.App {
  width: 100%;
  height: 100%;
  .side-side-panel__header__bottom {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    min-height: 37px;
    padding: 0 !important;
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.App
  div
  div
  div
  .side-panel--container
  div
  div
  .side-side-panel__header__bottom
  [data-for="layer-nav"] {
  display: none;
}
.App
  div
  div
  div
  .side-panel--container
  div
  div
  .side-side-panel__header__bottom
  [data-for="filter-nav"] {
  display: none;
}

.App
  div
  div
  div
  .side-panel--container
  div
  div
  .side-side-panel__header__bottom
  [data-for="interaction-nav"] {
  display: none;
}
.App
  div
  div
  div
  .side-panel--container
  div
  div
  .side-side-panel__header__bottom
  [data-for="map-nav"] {
  display: none;
}

a.mapboxgl-ctrl-logo {
  width: 90px !important;
}
