.side-panel--container {
  max-height: 70%;
  min-height: 700px;
}

.side-panel__tab {
  margin-top: 8px;
}

.side-bar__close {
  top: 96px !important;
}

.side-panel__content__title {
  font-family: "Lato";
  color: #212121 !important;
  font-weight: 700 !important;
  letter-spacing: 0px !important;
  margin-bottom: 8px !important;
}

.header-container {
  font-family: "Lato";
  padding: 16px;
  display: flex;

  .export-container {
    display: grid;
    align-items: center;
    justify-items: end;
    img {
      cursor: pointer;
      width: 20px;
      margin-right: 5px;
    }
  }
  .wisogis-logo {
    position: relative;
    align-self: center;
    height: 50px;
  }
  .tractebel-logo {
    height: 30px;
    margin: auto;
  }
  padding-bottom: 10px;
}

.mapboxgl-ctrl-scale {
  background-color: #ffffff42 !important;
  border: 1px solid #24242484 !important;
  border-top: 0 !important;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  margin: 0 60px 50px 0 !important;
  float: right;
}

.map-legend {
  background-color: #f7f7f7;
}
