@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap");
@import "~material-design-icons/iconfont/material-icons.css";
$icon-font-path: "~@engie-group/fluid-design-system/src/components/icon/fonts/";
@import "~@engie-group/fluid-design-system/src/fluid-design-system.scss";

body,
html,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
